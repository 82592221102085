<template>
  <CustomBottomSheet
    :refName="'MonitorScoreExamShowMedia'"
    size="xl"
    :headerText="$t('SharedMedias.data')"
    :headerIcon="MonitorScoreExam.icon"
  >
    <div class="row">
      <ShowMedia :class="'col-md-12'" :media="MonitorScoreExam" />
    </div>
  </CustomBottomSheet>
</template>

<script>
import CustomBottomSheet from "./../../../../components/general/CustomBottomSheet.vue";
import ShowMedia from "./ShowMedia.vue";

import {
  isDataExist,
  fullPathFileFromServer,
} from "./../../../../utils/functions";

export default {
  components: {
    CustomBottomSheet,
    ShowMedia,
  },
  props: ["MonitorScoreExam"],
  data() {
    return {};
  },
  methods: {
    isDataExist,
    fullPathFileFromServer,
  },
};
</script>
