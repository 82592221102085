<template>
  <div v-if="MonitorScoreExamsData" class="table-container">
    <table class="my-table">
      <thead>
        <tr>
          <th rowspan="2">#</th>
          <th colspan="6">{{ $t("MonitorScoreExams.data") }}</th>
          <th rowspan="2">{{ $t("EducationalGroups.name") }}</th>
          <th colspan="2">{{ $t("students.data") }}</th>
          <th rowspan="2"><i class="fas fa-sliders-h"></i></th>
        </tr>
        <tr>
          <th>{{ $t("media") }}</th>
          <th>{{ $t("general.code") }}</th>
          <th>{{ $t("general.name") }}</th>
          <th>{{ $t("general.type") }}</th>
          <th>{{ $t("MonitorScoreExams.finalScore") }}</th>
          <th>{{ $t("MonitorScoreExams.studentScore") }}</th>

          <th>{{ $t("general.code") }}</th>
          <th>{{ $t("general.name") }}</th>
        </tr>
      </thead>
      <tbody>
        <tr
          v-for="(MonitorScoreExam, index) in MonitorScoreExamsData"
          :key="index"
        >
          <td>{{ ++index + filterData.currentIndex }}</td>
          <td>
            <button
              @click="
                setMonitorScoreExamData(MonitorScoreExam);
                openBottomSheet('MonitorScoreExamShowMedia');
              "
              :title="$t('media')"
            >
              <img src="@/assets/images/media.svg" />
            </button>
          </td>
          <td>{{ isDataExist(MonitorScoreExam.fullCode) }}</td>
          <td>{{ isDataExist(MonitorScoreExam.examNameCurrent) }}</td>
          <td>{{ isDataExist(MonitorScoreExam.examTypeNameCurrent) }}</td>
          <td>{{ isDataExist(MonitorScoreExam.examFinalScore) }}</td>
          <td>{{ isDataExist(MonitorScoreExam.studentScore) }}</td>
          <td>
            {{
              isDataExist(
                MonitorScoreExam.educationalGroupInfoData
                  .educationalGroupNameCurrent
              )
            }}
          </td>
          <td>
            {{ isDataExist(MonitorScoreExam.userStudentInfoData.fullCode) }}
          </td>
          <td>
            {{
              isDataExist(MonitorScoreExam.userStudentInfoData.userNameCurrent)
            }}
          </td>

          <td class="fmenu-item-container">
            <FloatingMenu>
              <li>
                <button
                  @click="
                    setMonitorScoreExamData(MonitorScoreExam);
                    openBottomSheet('ActionsData');
                  "
                  :title="$t('actionsData.modelName')"
                >
                  <img src="@/assets/images/actions-data.svg" />
                </button>
              </li>
              <li v-if="checkPrivilege(hasMonitorScoreExamEdit())">
                <button
                  @click="
                    setMonitorScoreExamData(MonitorScoreExam);
                    openBottomSheet('MonitorScoreExamUpdate');
                  "
                  :title="$t('MonitorScoreExams.edit')"
                >
                  <img src="@/assets/images/pencil.svg" />
                </button>
              </li>
              <li v-if="checkPrivilege(hasMonitorScoreExamFinaleDelete())">
                <button
                  v-b-modal.MonitorScoreExamDelete
                  :title="$t('delete')"
                  @click="setMonitorScoreExamData(MonitorScoreExam)"
                >
                  <img src="@/assets/images/trash.svg" />
                </button>
              </li>
            </FloatingMenu>
          </td>
        </tr>
      </tbody>
    </table>
  </div>
</template>

<script>
import {
  checkPrivilege,
  isDataExist,
  fullPathFileFromServer,
  formateDateTimeLang,
} from "./../../../../utils/functions";
import {
  hasMonitorScoreExamEdit,
  hasMonitorScoreExamFinaleDelete,
  hasEducationalGroup,
  hasSimpleExamModel,
  hasStudentScheduleExamTimeReport,
} from "./../../../../utils/privilegeHelper";
import FloatingMenu from "./../../../../components/general/FloatingMenu.vue";
import generalMixin from "./../../../../utils/generalMixin";

export default {
  name: "MonitorScoreExamsTable",
  mixins: [generalMixin],
  components: {
    FloatingMenu,
  },
  props: ["MonitorScoreExamsData", "filterData", "defaultImg"],

  data() {
    return {};
  },
  methods: {
    setMonitorScoreExamData(MonitorScoreExam) {
      let educationalGroupStudentTokens = [
        MonitorScoreExam.educationalGroupStudentToken,
      ];
      let monitorScoreStudentsDataHandler = [
        {
          educationalGroupStudentToken:
            MonitorScoreExam.educationalGroupStudentToken,
          studentScore: MonitorScoreExam.studentScore,
          student: {
            fullCode: MonitorScoreExam.userStudentInfoData.fullCode,
            userNameCurrent:
              MonitorScoreExam.userStudentInfoData.userNameCurrent,
          },
        },
      ];
      this.$emit("setMonitorScoreExamData", {
        ...MonitorScoreExam,
        monitorScoreStudentsDataHandler,
        educationalGroupStudentTokens,
      });
    },
    checkPrivilege,
    isDataExist,
    formateDateTimeLang,
    fullPathFileFromServer,
    hasMonitorScoreExamEdit,
    hasMonitorScoreExamFinaleDelete,
    hasEducationalGroup,
    hasSimpleExamModel,
    hasStudentScheduleExamTimeReport,
  },
};
</script>
