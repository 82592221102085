<template>
  <div class="">
    <DashboardNavbar
      :filterSheetName="'filterSheet'"
      :filterSheetStatus="true"
    />

    <PreLoader v-if="isLoading" />

    <div class="app-content pagination-with-content">
      <template v-if="hasData">
        <MonitorScoreExamTable
          v-on:setMonitorScoreExamData="
            MonitorScoreExams.MonitorScoreExam.fillData($event)
          "
          :MonitorScoreExamsData="MonitorScoreExams.MonitorScoreExamsData"
          :defaultImg="MonitorScoreExams.MonitorScoreExam.defaultImg"
          :filterData="MonitorScoreExams.filterData"
        />

        <ActionsData :actionsData="MonitorScoreExams.MonitorScoreExam" />

        <MonitorScoreExamDelete
          :MonitorScoreExam="MonitorScoreExams.MonitorScoreExam"
          v-on:refresh="getAllMonitorScoreExams()"
        />
      </template>
      <ExceptionWithImg
        v-if="exceptionMsg"
        :msg="exceptionMsg"
        :image="exceptionImg"
      />
      <CustomPagination
        v-if="hasData"
        :paginationData="MonitorScoreExams.filterData"
        v-on:changePagination="changePagination($event)"
      />
      <MonitorScoreExamFilter
        :theFilterData="MonitorScoreExams.filterData"
        v-on:search="search($event)"
      />

      <MonitorScoreExamUpdate
        :MonitorScoreExam="MonitorScoreExams.MonitorScoreExam"
        v-on:refresh="getAllMonitorScoreExams()"
      />

      <MonitorScoreExamShowMedia
        :MonitorScoreExam="MonitorScoreExams.MonitorScoreExam"
        v-on:refresh="getAllMonitorScoreExams()"
      />
    </div>
  </div>
</template>

<script>
import { STATUS, VIEW_TYPES } from "./../../../utils/constants";
import DashboardNavbar from "./../../../layouts/components/DashboardNavbar.vue";
import PreLoader from "./../../../components/general/PreLoader.vue";
import ExceptionWithImg from "./../../../components/general/ExceptionWithImg.vue";
import ActionsData from "./../../../components/general/ActionsData.vue";
import CustomPagination from "./../../../components/general/CustomPagination.vue";
import MonitorScoreExamUpdate from "./components/MonitorScoreExamUpdate.vue";
import MonitorScoreExamShowMedia from "./components/MonitorScoreExamShowMedia.vue";
import MonitorScoreExamTable from "./components/MonitorScoreExamTable.vue";
import MonitorScoreExamDelete from "./components/MonitorScoreExamDelete.vue";
import MonitorScoreExamFilter from "./components/MonitorScoreExamFilter.vue";
import MonitorScoreExams from "./../../../models/educational/monitorScoreExams/MonitorScoreExams";
import apiMonitorScoreExam from "./../../../api/educational/monitorScoreExams";
import { getDialogOfActivationTypes } from "./../../../utils/dialogsOfConstantsLists";
import generalMixin from "./../../../utils/generalMixin";
import generalSettingsMixin from "./../../../utils/generalSettingsMixin";
import { checkPrivilege } from "./../../../utils/functions";
// import { hasMonitorScoreExamAdd } from "./../../../utils/privilegeHelper";

export default {
  mixins: [generalMixin, generalSettingsMixin],
  components: {
    DashboardNavbar,
    PreLoader,
    ExceptionWithImg,
    ActionsData,
    CustomPagination,
    MonitorScoreExamUpdate,
    MonitorScoreExamShowMedia,
    MonitorScoreExamTable,
    MonitorScoreExamDelete,
    MonitorScoreExamFilter,
  },
  computed: {
    hasData() {
      return this.MonitorScoreExams.MonitorScoreExamsData.length != 0;
    },
  },
  data() {
    return {
      MonitorScoreExams: new MonitorScoreExams(),
      VIEW_TYPES,
      activationTypesTokenOptions: getDialogOfActivationTypes(),
      educationalGroupToken: this.$route.params.educationalGroupToken,
    };
  },
  methods: {
    checkPrivilege,
    // hasMonitorScoreExamAdd,
    changePagination(pagination) {
      this.MonitorScoreExams.filterData.fillData(pagination);
      this.getAllMonitorScoreExams();
    },
    search(data) {
      this.MonitorScoreExams.filterData.fillData(data);
      this.getAllMonitorScoreExams();
    },
    async getAllMonitorScoreExams() {
      this.isLoading = true;
      try {
        this.MonitorScoreExams.filterData.educationalGroupInfoDataInclude = true;
        this.MonitorScoreExams.filterData.simpleExamModelInfoDataInclude = true;
        this.MonitorScoreExams.filterData.educationalCategoryInfoDataInclude = true;
        this.MonitorScoreExams.MonitorScoreExamsData = [];
        const response = await apiMonitorScoreExam.getAll(
          this.MonitorScoreExams.filterData
        );
        if (response.data.status == STATUS.SUCCESS) {
          this.exceptionMsg = null;
        } else if (response.data.status == STATUS.NO_INTERNET) {
          this.exceptionMsg = response.data.msg;
          this.exceptionImg = "illustrator-noInternet.svg";
        } else if (response.data.status == STATUS.NO_CONTENT) {
          this.exceptionMsg = response.data.msg;
          this.exceptionImg = "illustrator-noData.svg";
        } else if (response.data.status == STATUS.INVALID_TOKEN) {
          this.$store.dispatch("logoutUser", response.data.msg);
        } else {
          this.exceptionMsg = response.data.msg;
          this.exceptionImg = null;
        }
        this.MonitorScoreExams.fillData(response.data);
      } catch (error) {
        this.exceptionMsg = this.$t("errorCatch");
        this.exceptionImg = null;
      }
      this.isLoading = false;
    },
  },
  async created() {
    this.getAllMonitorScoreExams();
  },
};
</script>
