<template>
  <div>
    <PreLoader v-if="isLoading" />

    <CustomBottomSheet
      refName="MonitorScoreExamUpdate"
      size="xl"
      :headerText="$t('MonitorScoreExams.edit')"
      :headerIcon="MonitorScoreExam.icon"
      :clickToClose="false"
      :swipeAble="false"
      @opened="opened = true"
      @closed="opened = false"
    >
      <MonitorScoreExamForm
        v-if="!isLoading"
        id="update"
        :MonitorScoreExam="MonitorScoreExam"
        v-on:submitForm="updateMonitorScoreExam()"
        :opened="opened"
        :deleteFileStatus="true"
        v-on:deleteFile="deleteFile()"
        bottomSheetName="MonitorScoreExamUpdate"
        :submitName="$t('edit')"
      />
    </CustomBottomSheet>
  </div>
</template>

<script>
import PreLoader from "./../../../../components/general/PreLoader.vue";
import CustomBottomSheet from "./../../../../components/general/CustomBottomSheet.vue";
import MonitorScoreExamForm from "./MonitorScoreExamForm.vue";
import { STATUS } from "./../../../../utils/constants";
import { objectToFormData } from "./../../../../utils/functions";
import GeneralDelete from "./../../../../models/general/GeneralDelete";
import apiMonitorScoreExam from "./../../../../api/educational/monitorScoreExams";
import generalMixin from "./../../../../utils/generalMixin";

export default {
  mixins: [generalMixin],
  components: {
    PreLoader,
    CustomBottomSheet,
    MonitorScoreExamForm,
  },
  props: ["MonitorScoreExam"],
  data() {
    return {
      model: new GeneralDelete(),
      opened: false,
    };
  },

  methods: {
    async updateMonitorScoreExam() {
      this.isLoading = true;

      this.MonitorScoreExam.studentScore =
        this.MonitorScoreExam.monitorScoreStudentsDataHandler[0].studentScore;

      let formData = objectToFormData(this.MonitorScoreExam);
      try {
        const response = await apiMonitorScoreExam.update(formData);
        if (response.data.status == STATUS.SUCCESS) {
          this.showMsg(response.data.msg, true);
          this.closeBottomSheet("MonitorScoreExamUpdate");
          this.$emit("refresh");
        } else if (response.data.status == STATUS.INVALID_TOKEN) {
          this.$store.dispatch("logoutUser", response.data.msg);
          this.showMsg(response.data.msg);
        } else {
          this.showMsg(response.data.msg);
        }
      } catch (error) {
        this.showMsg(this.$t("errorCatch"));
      }
      this.isLoading = false;
    },
    async deleteFile() {
      this.isLoading = true;
      try {
        this.model.fillData({
          token: this.MonitorScoreExam.MonitorScoreExamToken,
        });
        let formData = objectToFormData(this.model);
        const response = await apiMonitorScoreExam.deleteImage(formData);
        if (response.data.status == STATUS.SUCCESS) {
          this.showMsg(response.data.msg, true);
          this.MonitorScoreExam.MonitorScoreExamImagePath = "";
          this.MonitorScoreExam.MonitorScoreExamImageIsDefault = true;
          this.$emit("refresh");
        } else if (response.data.status == STATUS.INVALID_TOKEN) {
          this.showMsg(response.data.msg);
          this.$store.dispatch("logoutUser", response.data.msg);
        } else {
          this.showMsg(response.data.msg);
        }
      } catch (error) {
        this.showMsg(this.$t("errorCatch"));
      }
      this.isLoading = false;
    },
  },
};
</script>
